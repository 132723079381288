<template>
  <h1 style="font-size:2rem;text-align:center;padding:4rem">Vui lòng đợi ...</h1>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import {
} from "bootstrap-vue";

export default {
  components: {
  },
  data() {
    return {
      so_tb: "",
    };
  },
  watch: {},
  created() {
    this.loading(true);
    useJwt.get("shortLink/"+this.$route.params.id+"/"+this.$route.params.campaign_name).then((response) => {
      var p = response.data.result;
      let url = ''
      if(p.url)
        url = p.url
      else if (p.Url)
        url = p.Url
      const newLink = url +'?utm_source='+p.utm_source+'&utm_medium='+p.utm_medium+'&utm_content='+p.utm_content+'&utm_campaign='+p.utm_campaign+'&sim_category_id_sort='+p.sim_category_id;
      document.location.href=newLink;
      this.loading(false);
    })
  },
  methods: {
  }
};
</script>